function DOMready(callback) {
  if (document.readyState != 'loading') callback()
  else document.addEventListener('DOMContentLoaded', callback)
}

DOMready(function () {
  const selectors = {
    mobileParent1: '.mobile-menu__link-parent-1',
    mobileParent2: '.mobile-menu__link-parent-2',
    mobileChild: '.mobile-menu__sublinks.mobile-menu__child-list',
    mobileChild2: '.mobile-menu__products.mobile-menu__child-list',
  }

  const mobileParent1Array = document.querySelectorAll(selectors.mobileParent1)
  const mobileParent2Array = document.querySelectorAll(selectors.mobileParent2)

  slideupMobileMenu(
    mobileParent1Array,
    selectors.mobileChild,
    selectors.mobileParent1
  )
  slideupMobileMenu(
    mobileParent2Array,
    selectors.mobileChild2,
    selectors.mobileParent1
  )

  function slideupMobileMenu(array, array2, array3) {
    if (array.length > 0) {
      array.forEach((item) => {
        const subItemArray2 = item.querySelectorAll(array2)
        const findItemArray2All = document.querySelectorAll(array2)
        const findItemArray3All = document.querySelectorAll(array3)

        if (subItemArray2.length > 0) {
          item.children[0].addEventListener('click', function (e) {
            e.preventDefault()
            this.parentElement.classList.toggle('active')

            findItemArray2All.forEach((itemArray2) => {
              if (itemArray2.parentElement.children[0] != this) {
                itemArray2.parentElement.children[0].parentElement.classList.remove(
                  'active'
                )
                itemArray2.style.display = ''
                findItemArray3All.forEach((itemArray3) => {
                  itemArray3.style.maxHeight = itemArray3.scrollHeight + 'px'
                })
              } else {
                if (!this.parentElement.classList.contains('active')) {
                  itemArray2.style.display = ''
                  this.parentElement.classList.remove('active')
                  itemArray2.parentElement.style.maxHeight = null
                } else {
                  itemArray2.style.display = 'block'

                  if (itemArray2.parentElement.dataset.level == 2) {
                    itemArray2.parentElement.style.maxHeight =
                      itemArray2.parentElement.scrollHeight + 'px'
                  }

                  setTimeout(() => {
                    findItemArray3All.forEach((itemArray3) => {
                      if (
                        itemArray3 === itemArray2.parentElement ||
                        itemArray3 ===
                          itemArray2.parentElement.parentElement.parentElement
                      ) {
                        itemArray3.style.maxHeight =
                          itemArray3.scrollHeight + 'px'
                      }
                    })
                  }, 550)
                }
              }
            })
          })
        }
      })
    }
  }

  const otherWebsites = document.querySelectorAll('.header__other-websites')

  if (otherWebsites.length > 0) {
    const websitesBtn = document.querySelector(
      '.header__other-websites .websites-btn'
    )

    websitesBtn.addEventListener('click', function (e) {
      e.preventDefault()

      const dropdown = this.parentElement.querySelector('.dropdown')
      const overlayDiv = document.createElement('div')
      overlayDiv.classList.add('websites-overlay')

      this.parentElement.appendChild(overlayDiv)
      dropdown.classList.toggle('js-show')

      overlayEvent()
    })

    function overlayEvent() {
      const overlayWebsites = document.querySelector('.websites-overlay')

      if (overlayWebsites != null) {
        overlayWebsites.addEventListener('click', function (e) {
          const dropdown = document.querySelector(
            '.header__other-websites .dropdown'
          )

          if (dropdown.classList.contains('js-show')) {
            dropdown.classList.remove('js-show')
            this.remove()
          }
        })
      }
    }
  }
})
